// components/tracking/MetaPixel.tsx
'use client';

import { useEffect } from 'react';
import Script from 'next/script';
import { useConsentCheck } from '@/app/hooks/useConsentCheck';

// We no longer declare fbq in global interface since it's already declared in klaro.d.ts

export default function MetaPixel() {
  const { hasConsent } = useConsentCheck('facebook');
  const PIXEL_ID = '2088693184903493';

  useEffect(() => {
    // Only set up tracking methods if consent is given
    if (hasConsent) {
      console.log('Facebook consent granted, setting up tracking methods');

      // Track ViewContent event - expose globally
      window.trackViewContent = (
        contentIds: string[],
        contentType: 'product' | 'product_group' = 'product',
        eventId?: string
      ) => {
        if (typeof window.fbq === 'function') {
          console.log('Tracking ViewContent event:', {
            content_ids: contentIds,
            content_type: contentType,
            event_id: eventId
          });

          const eventParams: any = {
            content_ids: contentIds,
            content_type: contentType
          };

          // Add event_id if provided
          if (eventId) {
            eventParams.event_id = eventId;
          }

          window.fbq('track', 'ViewContent', eventParams);
        } else {
          console.log('Cannot track ViewContent: fbq not available');
        }
      };

      // Track Purchase event - expose globally
      window.trackPurchase = (
        contentIds: string[],
        value: number,
        currency: string = 'EUR',
        contentType: 'product' | 'product_group' = 'product',
        eventId?: string
      ) => {
        if (typeof window.fbq === 'function') {
          console.log('Tracking Purchase event:', {
            content_ids: contentIds,
            value: value,
            currency: currency,
            content_type: contentType,
            event_id: eventId
          });

          const eventParams: any = {
            content_ids: contentIds,
            value: value,
            currency: currency,
            content_type: contentType,
            event_name: "Purchase",
          };

          // Add event_id if provided
          if (eventId) {
            eventParams.event_id = eventId;
          }

          window.fbq('track', 'Purchase', eventParams);
        } else {
          console.log('Cannot track Purchase: fbq not available');
        }
      };
    } else {
      // If no consent, remove tracking methods
      delete window.trackViewContent;
      delete window.trackPurchase;
    }

    return () => {
      // Clean up global methods
      delete window.trackViewContent;
      delete window.trackPurchase;
    };
  }, [hasConsent]);

  // Only render if there is consent
  if (!hasConsent) {
    console.log('Facebook consent not granted, not loading pixel script');
    return null;
  }

  console.log('Facebook consent granted, loading pixel script');

  // Use Facebook's exact initialization code structure with dangerouslySetInnerHTML
  return (
    <>
      <Script
        id="meta-pixel-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${PIXEL_ID}');
            fbq('track', 'PageView');
          `
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=1`}
          alt=""
        />
      </noscript>
    </>
  );
}