'use client'

import { useEffect, useState } from 'react';
import { trackGuideProfileView, trackBookingComplete } from '@/app/api/actions/tracking-actions';

export function useTracking() {
  const [isTracking, setIsTracking] = useState(false);

  /**
   * Tracks a guide profile view
   */
  const trackGuideView = async (guideId: string) => {
    if (isTracking) return;
    setIsTracking(true);

    try {
      await trackGuideProfileView(guideId);
    } finally {
      setIsTracking(false);
    }
  };

  /**
   * Tracks a booking completion
   */
  const trackBooking = async (orderId: string, guideId: string) => {
    if (isTracking) return;
    setIsTracking(true);

    try {
      await trackBookingComplete(orderId, guideId);
    } finally {
      setIsTracking(false);
    }
  };

  return {
    trackGuideView,
    trackBooking,
    isTracking
  };
}