// components/consent/ConsentBanner.tsx
'use client'

import { useState } from 'react';
import { useConsent } from '@/app/contexts/ConsentContext';

export default function ConsentBanner() {
  const {
    services,
    consents,
    showBanner,
    updateConsent,
    acceptAll,
    acceptRequired,
    saveConsents
  } = useConsent();

  const [showDetails, setShowDetails] = useState(false);

  if (!showBanner) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-black bg-opacity-25"></div>

      <div className="relative p-4 min-h-screen flex items-center justify-center">
        <div className="relative bg-white rounded-lg shadow-xl max-w-3xl w-full mx-auto p-6">
          <div className="mb-4">
            <h2 className="text-xl font-bold text-gray-900">Informationen zur Datenverarbeitung</h2>
            <p className="mt-2 text-sm text-gray-600">
              Wir legen großen Wert auf deine Privatsphäre. Aktuell verwenden wir ausschließlich
              technisch notwendige Cookies sowie optional Marketing- und Analysewerkzeuge.
            </p>
            <p className="mt-2 text-sm text-gray-600">
              Die technischen Cookies sind notwendig für die sichere Anmeldung und Kommunikation,
              die Durchführung von Zahlungen sowie das Speichern deiner Cookie-Einstellungen.
            </p>
          </div>

          {showDetails && (
            <div className="mb-4 border-t border-b border-gray-200 py-4">
              <h3 className="font-medium text-gray-900 mb-2">Cookie-Kategorien</h3>

              {/* Technical Services (Always Required) */}
              <div className="mb-4">
                <div className="flex items-center justify-between">
                  <h4 className="font-medium">Technisch notwendige Dienste</h4>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={consents.technical}
                      onChange={() => {}} // Always enabled, no change allowed
                      disabled
                      className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
                    />
                    <span className="ml-2 text-xs text-gray-500">(notwendig)</span>
                  </div>
                </div>
                <div className="mt-2 pl-4">
                  {services
                    .filter(service => service.category === 'technical')
                    .map(service => (
                      <div key={service.id} className="mb-3">
                        <div className="text-sm font-medium">{service.name}</div>
                        <p className="text-xs text-gray-500">{service.description}</p>
                      </div>
                    ))}
                </div>
              </div>

              {/* Marketing Services */}
              <div>
                <div className="flex items-center justify-between">
                  <h4 className="font-medium">Marketing und Analyse</h4>
                  <div>
                    <input
                      type="checkbox"
                      checked={consents.marketing}
                      onChange={(e) => updateConsent('marketing', e.target.checked)}
                      className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary-dark"
                    />
                  </div>
                </div>
                <div className="mt-2 pl-4">
                  {services
                    .filter(service => service.category === 'marketing')
                    .map(service => (
                      <div key={service.id} className="mb-3">
                        <div className="text-sm font-medium">{service.name}</div>
                        <p className="text-xs text-gray-500">{service.description}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0 sm:space-x-2">
            <button
              onClick={() => setShowDetails(!showDetails)}
              className="text-sm text-gray-600 underline"
            >
              {showDetails ? 'Details ausblenden' : 'Details anzeigen'}
            </button>

            <div className="flex space-x-2">
              <button
                onClick={acceptRequired}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-300 text-sm"
              >
                Nur Notwendige
              </button>
              <button
                onClick={acceptAll}
                className="px-4 py-2 bg-primary text-white rounded-full hover:bg-primary-dark text-sm"
              >
                ALLE AKZEPTIEREN
              </button>
              {showDetails && (
                <button
                  onClick={saveConsents}
                  className="px-4 py-2 bg-primary uppercase text-white rounded-full hover:bg-primary-dark text-sm"
                >
                  Auswahl bestätigen
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}