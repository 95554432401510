// components/tracking/TrackingProvider.tsx
'use client'

import React, { createContext, useContext, ReactNode } from 'react';
import { useTracking } from '@/app/hooks/useTracking';
import {PostHogProvider} from "@/components/tracking/PostHogProvider";

type TrackingContextType = ReturnType<typeof useTracking>;

const TrackingContext = createContext<TrackingContextType | undefined>(undefined);

export function useTrackingContext() {
  const context = useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTrackingContext must be used within a TrackingProvider');
  }
  return context;
}

interface TrackingProviderProps {
  children: ReactNode;
}

export function TrackingProvider({ children }: TrackingProviderProps) {
  const tracking = useTracking();

  return (
    <PostHogProvider>
      <TrackingContext.Provider value={tracking}>
        {children}
      </TrackingContext.Provider>
    </PostHogProvider>
  );
}