// components/tracking/GoogleAds.tsx
'use client';

import Script from 'next/script';

export default function GoogleAds() {
  const GOOGLE_ADS_ID = 'AW-16942094959';

  console.log('Google Ads consent granted, ready for tracking');

  return (
    <>
      <Script
        id="google-ads-script"
        strategy="afterInteractive"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADS_ID}`}
      />
      <Script
        id="google-ads-inline-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GOOGLE_ADS_ID}');
          `
        }}
      />
    </>
  );
}