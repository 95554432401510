'use client';

import { SessionProvider } from "next-auth/react";
import { ConfigProvider } from '@/components/providers/ConfigProvider';

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <SessionProvider>
      <ConfigProvider>
        {children}
      </ConfigProvider>
    </SessionProvider>
  );
}