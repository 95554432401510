// components/tracking/ConsentBasedTracking.tsx
'use client'

import { useConsent } from '@/app/contexts/ConsentContext';
import MetaPixel from './MetaPixel';
import GoogleAds from './GoogleAds';

/**
 * Component that conditionally renders tracking components based on consent state
 * Only renders tracking components when consent is fully initialized
 */
export default function ConsentBasedTracking() {
  const { isInitialized, consents } = useConsent();

  // Don't render anything until consent is fully initialized
  if (!isInitialized) {
    console.log('Consent system initializing, deferring tracking...');
    return null;
  }

  console.log('Consent system initialized with state:', consents);

  return (
    <>
      {/* Only render tracking components if marketing consent is granted */}
      {consents.marketing && (
        <>
          <MetaPixel />
          <GoogleAds />
          {/* Add other tracking components here */}
        </>
      )}
    </>
  );
}