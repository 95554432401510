// components/providers/ConfigProvider.tsx
'use client';

import { useEffect, ReactNode } from 'react';
import { useConfigStore } from '@/app/lib/stores/configStore';

interface ConfigProviderProps {
  children: ReactNode;
}

/**
 * Provider component that initializes application configuration
 * Initializes silently without blocking rendering
 *
 * @param children Components that will render immediately while config loads in background
 */
export function ConfigProvider({ children }: ConfigProviderProps) {
  const { initialize, isInitialized, isLoading } = useConfigStore();

  useEffect(() => {
    if (!isInitialized && !isLoading) {
      // Initialize in the background
      initialize().catch(error => {
        // Log errors but don't block rendering
        console.error('Configuration initialization error:', error);
      });
    }
  }, [initialize, isInitialized, isLoading]);

  // Always render children immediately, don't block on config loading
  return <>{children}</>;
}