// components/tracking/PostHogPageView.tsx
'use client'

import { useEffect, Suspense } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { usePostHog } from 'posthog-js/react'

function PageViewInner() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const posthog = usePostHog()

  // Track pageviews
  useEffect(() => {
    // Make sure PostHog is initialized and pathname is available
    if (pathname && posthog) {
      let url = window.origin + pathname
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`
      }
      // Send the pageview event
      posthog.capture(
        '$pageview',
        {
          '$current_url': url,
        }
      )
    }
    // Trigger effect on pathname, searchParams, or posthog instance change
  }, [pathname, searchParams, posthog])

  // This component doesn't render anything itself
  return null
}

// Wrap the inner component in Suspense to handle potential server-side rendering issues
// with useSearchParams as recommended by Next.js and PostHog docs.
export default function PostHogPageView(): JSX.Element {
  return (
    <Suspense fallback={null}>
      <PageViewInner />
    </Suspense>
  )
}