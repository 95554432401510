// app/api/actions/tracking-actions.ts
'use server'

import { headers } from 'next/headers';
import { getServerSession } from 'next-auth';
import { authOptions } from '@/app/lib/auth';
import { authenticatedFetch } from '@/app/lib/apiUtils';

const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL || '';
/**
 * Gets tracking data from server context
 */
export async function getTrackingData() {
  const headersList = headers();
  const userAgent = headersList.get('user-agent') || '';
  const forwardedFor = headersList.get('x-forwarded-for') || '';
  const xRealIp = headersList.get('x-real-ip') || '';
  const clientIp = forwardedFor ? forwardedFor.split(',')[0].trim() : xRealIp || '';

  // Get session for email
  const session = await getServerSession(authOptions);
  const customerEmail = session?.user?.email || '';
  const customerId = session?.user?.id || '';

  return {
    userAgent,
    clientIp,
    customerEmail,
    customerId
  };
}


/**
 * Tracks a product view event
 * @param productId The ID of the product being viewed
 * @param productName Optional product name for better tracking
 */
export async function trackProductView(productId: string, productName?: string) {
  const trackingData = await getTrackingData();

  try {
    await authenticatedFetch(`${API_BASE_URL}/ktor/tracking/product_view`, {
      method: 'POST',
      headers: {
        'X-User-Agent': trackingData.userAgent,
        'X-Client-IP': trackingData.clientIp,
        'X-Customer-Email': trackingData.customerEmail,
        'X-Customer-Id': trackingData.customerId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        eventData: {
          productId: productId,
          productName: productName || ''
        }
      })
    });

    return { success: true };
  } catch (error) {
    console.error('Error tracking product view:', error);
    // Fail silently - tracking errors shouldn't break the application
    return { success: false, error };
  }
}




/**
 * Tracks a custom event
 * @param eventType The type of event to track
 * @param data Additional data to include with the event
 */
export async function trackCustomEvent(eventType: string, data: Record<string, string> = {}) {
  const trackingData = await getTrackingData();

  // Ensure we have a valid base URL
  if (!API_BASE_URL) {
    console.error('API_BASE_URL is not defined');
    return { success: false, error: 'API_BASE_URL is not defined' };
  }
  try {
    // Ensure we have a full URL
    const url = `${API_BASE_URL}/ktor/tracking/${eventType}`;
    console.log(`Sending tracking event to: ${url}`);

    await authenticatedFetch(url, {
      method: 'POST',
      headers: {
        'X-User-Agent': trackingData.userAgent,
        'X-Client-IP': trackingData.clientIp,
        'X-Customer-Email': trackingData.customerEmail,
        'X-Customer-Id': trackingData.customerId,
        'Content-Type': 'application/json'
      },
      // Format data to match our DTO
      body: JSON.stringify({
        eventData: data
      })
    });
    return { success: true };
  } catch (error) {
    console.error(`Error tracking ${eventType}:`, error);
    // Fail silently - tracking errors shouldn't break the application
    return { success: false, error };
  }
}

/**
 * Tracks a category view event
 * @param categoryId The ID of the category being viewed
 */
export async function trackCategoryView(categoryId: string) {
  const trackingData = await getTrackingData();
  console.log("DEBUG Tracking Category " + `${API_BASE_URL}/ktor/tracking/category_view`)
  try {
    await authenticatedFetch(`${API_BASE_URL}/ktor/tracking/category_view`, {
      method: 'POST',
      headers: {
        'X-User-Agent': trackingData.userAgent,
        'X-Client-IP': trackingData.clientIp,
        'X-Customer-Email': trackingData.customerEmail,
        'X-Customer-Id': trackingData.customerId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        eventData: {
          categoryId: categoryId
        }
      })
    });
    return { success: true };
  } catch (error) {
    console.error('Error tracking category view:', error);
    // Fail silently - tracking errors shouldn't break the application
    return { success: false, error };
  }
}



/**
 * Tracks a guide profile view
 */
export async function trackGuideProfileView(guideId: string) {
  const trackingData = await getTrackingData();

  try {
    // Match the route structure defined in TrackingRoutes.kt
    await authenticatedFetch(`${API_BASE_URL}/ktor/tracking/guide_profile_view`, {
      method: 'POST',
      headers: {
        'X-User-Agent': trackingData.userAgent,
        'X-Client-IP': trackingData.clientIp,
        'X-Customer-Email': trackingData.customerEmail,
        'X-Customer-Id': trackingData.customerId,
        'Content-Type': 'application/json'
      },
      // Format data to match the TrackingEventDTO structure
      body: JSON.stringify({
        eventData: {
          guideId: guideId
        }
      })
    });
    return { success: true };
  } catch (error) {
    console.error('Error tracking guide profile view:', error);
    // Fail silently - tracking errors shouldn't break the application
    return { success: false, error };
  }
}
/**
 * Tracks a booking completion
 */
export async function trackBookingComplete(orderId: string, guideId: string) {
  console.warn("TRACKING COMPLETE")
  const trackingData = await getTrackingData();
  try {
    await authenticatedFetch(`${API_BASE_URL}/ktor/tracking/booking-complete`, {
      method: 'POST',
      headers: {
        'X-User-Agent': trackingData.userAgent,
        'X-Client-IP': trackingData.clientIp,
        'X-Customer-Email': trackingData.customerEmail,
        'X-Customer-Id': trackingData.customerId
      },
      body: JSON.stringify({
        orderId,
        guideId
      })
    });
    return { success: true };
  } catch (error) {
    console.error('Error tracking booking completion:', error);
    return { success: false, error };
  }
}