// components/tracking/PostHogProvider.tsx
'use client'

import { ReactNode, useEffect, useState } from "react"
import posthog from 'posthog-js'
import { PostHogProvider as PHProvider } from 'posthog-js/react'
import { useSession } from "next-auth/react"
import PostHogPageView from "./PostHogPageView";
import { useConsent } from '@/app/contexts/ConsentContext';

interface PostHogProviderProps {
  children: ReactNode;
}

interface Config {
  posthogKey: string;
  posthogHost: string;
  debugMode: boolean;
}

/**
 * Provider component that initializes PostHog analytics
 * and handles user identification.
 * Only enables tracking in production environments and when consent is granted.
 */
export function PostHogProvider({children}: PostHogProviderProps) {
  const {data: session} = useSession();
  const [config, setConfig] = useState<Config | null>(null);
  const [isProduction, setIsProduction] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { consents, isInitialized } = useConsent();
  const hasConsent = isInitialized && consents.marketing;

  // Load config once on mount
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const fetchConfig = async () => {
      try {
        const response = await fetch('/api/config/stripe');
        if (!response.ok) throw new Error('Failed to load configuration');
        const data = await response.json();
        setConfig(data);

        // In production, debugMode will be 'false' (whether the var is missing or explicitly set)
        // Only enable debug mode (disable production) when debugMode is explicitly 'true'
        setIsProduction(data.debugMode !== 'true');
        setIsLoaded(true);
      } catch (error) {
        console.error('Error loading configuration:', error);
        // On error, assume production for safety
        setIsProduction(true);
        setIsLoaded(true);
      }
    };

    fetchConfig();
  }, []);

  // Initialize PostHog when config is loaded and consent is granted
  useEffect(() => {
    // Don't do anything until consent is determined and config is loaded
    if (!isInitialized || !config || !isLoaded) return;

    // Handle initialization and shutdown based on consent
    if (hasConsent && isProduction) {
      // Initialize PostHog with config
      if (config.posthogKey) {
        console.log('Initializing PostHog with consent...');
        posthog.init(config.posthogKey, {
          api_host: config.posthogHost,
          capture_pageview: false, // Don't capture on init, we'll handle this separately
          autocapture: true,
          ui_host: config.posthogHost
        });
        posthog.startSessionRecording();
      }
    } else {
      // If no consent or not in production, reset PostHog if it's active
      if (posthog.__loaded) {
        console.log('Resetting PostHog - no consent or not in production');
        posthog.reset();
      }
    }
  }, [hasConsent, isInitialized, config, isProduction, isLoaded]);

  // Handle user identification when session changes
  useEffect(() => {
    if (!hasConsent || !isProduction || !config || !session?.user?.id) return;

    // Identify user if PostHog is loaded
    if (posthog.__loaded) {
      console.log('Identifying user in PostHog:', session.user.id);
      posthog.identify(session.user.id, {
        name: session.user.name || undefined,
        email: session.user.email || undefined,
        role: session.user.role || undefined
      });
    }
  }, [session, hasConsent, isProduction, config]);

  // Listen for consent changes to reinitialize
  useEffect(() => {
    const handleConsentUpdate = (e: CustomEvent) => {
      const newConsents = e.detail;
      if (newConsents && typeof newConsents === 'object' && 'marketing' in newConsents) {
        // If marketing consent changed from true to false, reset PostHog
        if (!newConsents.marketing && posthog.__loaded) {
          console.log('Consent revoked, resetting PostHog');
          posthog.reset();
        }
        // If marketing consent changed from false to true, reinitialize
        else if (newConsents.marketing && !posthog.__loaded && config?.posthogKey && isProduction) {
          console.log('Consent granted, reinitializing PostHog');
          posthog.init(config.posthogKey, {
            api_host: config.posthogHost,
            capture_pageview: false,
            autocapture: true,
            ui_host: config.posthogHost
          });
          posthog.startSessionRecording();
        }
      }
    };

    window.addEventListener('consent-updated', handleConsentUpdate as EventListener);

    return () => {
      window.removeEventListener('consent-updated', handleConsentUpdate as EventListener);
    };
  }, [config, isProduction]);

  // Render the PostHog provider with children
  return (
    <PHProvider client={posthog}>
      {children}
      {/* Only render page view tracker if consent is granted and in production */}
      {hasConsent && isProduction && isLoaded && <PostHogPageView />}
    </PHProvider>
  );
}